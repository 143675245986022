.scrollableBox::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.scrollableBox2::-webkit-scrollbar {
  width: 4px;
}
.scrollableBox3::-webkit-scrollbar {
  width: 8px;
}

.scrollableBox2::-webkit-scrollbar-thumb,
.scrollableBox3::-webkit-scrollbar-thumb {
  background-color: black;
  border-radius: 50px;
}

.scrollableBox2::-webkit-scrollbar-track,
.scrollableBox3::-webkit-scrollbar-track {
  border: 1px solid black;
  border-radius: 50px;
}
/* CSS file or in your component's styles */
.calendar-container {
  width: 100%; /* Make the container full-width */
  max-width: 800px; /* Set a maximum width if needed */
  margin: 0 auto; /* Center the container */
}

/* Optional: Adjust styles for smaller screens */
@media (max-width: 600px) {
  .calendar-container {
    max-width: 100%; /* Allow the calendar to take full width on smaller screens */
  }
}
/* For iOS devices */

/* .MuiPaper-root:first-child{
    border-radius: 16px 16px 0 0 !important;
}
.MuiPaper-root:last-child{
    border-radius: 0 0 16px 16px !important;
} */
.MuiPaper-root.Mui-expanded {
  margin: 0 !important;
}

/* .MuiOutlinedInput-input {
    padding: 7px !important;
} */

button:disabled {
  cursor: not-allowed !important;
}

.dragAndDropArea.dragOver {
  border-style: solid;
  border-color: #1b97f5;
}

.tableBody .MuiOutlinedInput-notchedOutline {
  border-color: #d9cbb3 !important;
}

.customformlabel {
  text-transform: lowercase;
}

.customformlabel::first-letter,
.customformlabel span::first-letter {
  text-transform: capitalize;
}

.produitItemContainer:hover .actionsIcons {
  opacity: 1 !important;
}

.RemoveThePadding .css-19kzrtu {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.removePadding ul {
  padding: 0 !important;
}

.produitItem:hover .positionController {
  transition: 0.4s right ease;
  right: 0;
}

.produitItem::before {
  position: absolute;
  content: '';
  width: 18px;
  height: 100%;
  background-color: #ffc300;
  right: -100%;
  top: 0;
}
.CategoryItem::before {
  background-color: #e4b41b;
}
.produitItem:hover::before {
  transition: 0.4s right ease;
  right: 0;
}

.css-145v6pe-MuiButtonBase-root-MuiTabScrollButton-root {
  display: block;
}

.css-145v6pe-MuiButtonBase-root-MuiTabScrollButton-root.Mui-disabled {
  display: none;
}

.tooltipContainer::before {
  content: '';
  position: absolute;
  border: 10px solid transparent;
  width: 10px;
  height: 10px;
  top: 0;
  opacity: 0;
  transition: 0.2s top ease;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
}

.tooltipContainer.encore::before {
  border-bottom-color: #39cb7f;
}
.tooltipContainer.pasEncore::before {
  border-bottom-color: #fdc90f;
}
.tooltipContainer.passe::before {
  border-bottom-color: #fc4b6c;
}
.tooltipContainer:hover::before {
  opacity: 1;
  top: 4px;
}
.tooltipContainer:hover .tooltip {
  opacity: 1;
  top: 22px;
}
/* sidebar  */
.sidebar.closed .menuItem .bottom {
  opacity: 0;
  top: 5px;
  pointer-events: none;
  transition: top 0.3s ease;
}
.sidebar .menuItem .bottom:hover {
  background-color: #ff7b00;
}
.sidebar .menuItem:hover .bottom {
  opacity: 1;
  top: 0;
  /* transform: translateY(-50%); */
  pointer-events: all;
}
.sidebar .menuItem:hover .single {
  top: 50%;
  transform: translateY(-50%);
}

.sidebar .menuItem .subMenuItem,
.sidebar .menuItem .top {
  transition: background-color 0.5s ease;
}
/* .sidebar .menuItem .subMenuItem:hover,
.sidebar .menuItem .top:hover {
  background-color: #ff7b007a;
} */
/* .sidebar .menuItem .subMenuItem.selected,
.sidebar .menuItem .top.selected {
  background-color: #ff7b00;
  color: white;
} */
/* .sidebar .menuItem a {
  color: black;
} */
.sidebar .menuItem .subMenuItem.selected a,
.sidebar .menuItem .top.selected a {
  color: white !important;
}
/*  */
.toggle::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 25px;
  background: #ecf0f3;
  box-shadow: inset -3px -3px 7px #ffffff, inset 3px 3px 5px #ceced1;
}


 .statsHead{
    padding-bottom: 17px;
    padding-left: 17px;
    padding-right: 17px;
    border-radius: 8px;
    background-color: white;
    box-shadow: -2px 2px 5px lightgray;
 }
 @media (max-width:375px) {
    .cardsList .cardItem:first-child{
        grid-column: 1/3;
    }
 }
 @media (min-width:900px) {
    .container2 .block {
        width: calc(100% / 2 - 10px);
    }
 }
 @media (min-width:1200px) {
    .container2 .block {
        width: calc(100% / 3 - (40px / 3));
    }
 }




 body {
  padding-top: constant(safe-area-inset-top) !important; /* iOS 11.2+ */
  padding-bottom: constant(safe-area-inset-bottom) !important; /* iOS 11.2+ */
  padding-left: constant(safe-area-inset-left) !important; /* iOS 11.2+ */
  padding-right: constant(safe-area-inset-right) !important; /* iOS 11.2+ */
  padding-top: env(safe-area-inset-top) !important;
    padding-bottom: env(safe-area-inset-bottom) !important;
    padding-left: env(safe-area-inset-left) !important;
    padding-right: env(safe-area-inset-right) !important;
}

/* For newer devices with the notch */
/* @media screen and (max-width: 375px) {
  body {
    padding-top: env(safe-area-inset-top) !important;
    padding-bottom: env(safe-area-inset-bottom) !important;
    padding-left: env(safe-area-inset-left) !important;
    padding-right: env(safe-area-inset-right) !important;
  }
} */
@media print {
  .print-only {
    display: none;
  }
  
  .table-print {
    display: block !important;
  }
}